@import "vars"
  
.iconblock
  width: 950px

.iconblock2
  width: 100%
  padding: 0px 0px 50px 0px

.CGrid
  padding: 10px 20px 20px 10px
  margin: 0px




.ContentMain
  width: 100%

.ContentBlock
  width: calc(100% - 20%)

@media (max-width: 1024px)
  .ContentBlock
    width: calc(100%)


.Banner
    margin-right: auto
    margin-left: calc(50% - 200px)

    line-height: 1.1em

    font-family: 'Naga'
    font-weight: normal
    font-style: normal

    font-size: 2.5em
    display: flex
    width: 300px
    img
        width: 200px
        position: relative
        left: 300px

.Banner
    span
        position: absolute
        margin-top: 45px
        margin-left: -70px
        font-size: 50px
        width: 80px
    .Subtitle
        margin-left: 100px
        margin-top: 0px
        font-family: "Hibana"
        font-size: 15px
        color: $color1
        white-space: nowrap

.desc
  padding: 10px 20px 0px 20px
  font-style: italic

body
  width: 100%
  height: 100%
  font-family: 'Hibana'
  
  font-weight: thin
  
  background-color: $bgcolor1
  

h1
  color: $fontcolor1
  font-size: 4em
  margin-left: auto
  font-family: 'Oswald'
  font-weight: bold
  font-style: normal
  margin-top: 10px
@media (max-width: 767px)
  h1
    font-size: 2.8em
  

h2
  color: $fontcolor1
  font-size: 1.4em
  margin-left: 5px
  font-family: 'Oswald'
  font-weight: thin
  font-style: normal
  margin-top: 0px
  margin-bottom: 30px

.text-top
  margin-top: -20px

p
  font-size: 1.2em
  margin-top: 20px
  
.logo-top
  margin: 0px 0px 10px 0px

.logo-right
  margin: 0px 0px 10px 10px
