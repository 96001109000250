@import "vars"

.block_lrpddg
  margin-left: 20px!important
  margin-right: 20px!important

.bgorange
  background-color: rgba($color1,0.9)
.bgteal
  background-color: rgba($color1,0.9)
.bgwhite
  background-color: rgba(white,0.9)
.bgblack
  background-color: rgba(0,0,0)

