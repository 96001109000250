@import "VideoPreviewText.sass"
@import "vars"

.prevVideoContainer
  position: relative

.loader
  position: absolute
  top: calc(50% - 20px)
  left: calc(50% - 20px)

.element
  padding: 10px 0 0 10px
  margin: 0px
  border-radius: 10px
  cursor: pointer
  > img
    width: 600px
    border-radius: 0px
  .prevVideo
    width: 100%
    border-radius: 10px

.hide
  display: none
.show
  display: block


.shower
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: red

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.95)
    z-index: 9999
    color: white

.ShowerBlockyoutube
  padding: 0px 0px 10px 0px

@media (max-width: 992px)
  .ShowerBlockyoutube
    justify-content: center!important


@keyframes animaOverlayTextBlock
  0%
    opacity: 0
  50%
    opacity: 0
  100%
    opacity: 1

@media (max-width: 992px)
  .overlayTextBlock
    width: 720px !important
    height: 250px !important
    padding: 0px 50px 0px 50px

.overlayTextBlock
  width: 300px
  height: 410px
  padding: 20px 20px 20px 20px
  border-radius: 10px
  animation: animaOverlayTextBlock 1s
  hr
    width: 100%
    background-color: $bgcolor1
  h1
    font-family: "IBMPlexSerif"
    font-style: italic
    color: 000
    font-size: 1.4rem
    color: black
    background-color: white
  h2
    font-family: "Oswald"
    font-style: italic
    color: $color1
    font-size: 1rem
    background-color: white
    padding: 10px
  p
    font-family: "Oswald-Light"
    font-style: italic
    color: $color2
    font-size: 1.2rem
    background-color: white
    padding: 10px
  b
    color: $color1
.youtubePlayer
  animation: animaOverlayTextBlock 2s
  width: 720px

@media (max-width: 500px)
  .youtubePlayer
    width: 100%

@keyframes videopening
  0%
    width: 0%
  100%
    width: 100%
.videoShower
  animation: videopening 0.5s

.youtube-position
  justify-content: center!important
.videotext-position
  justify-content: flex-start!important

@media (max-width: 992px)
  .youtube-position
    justify-content: center!important
    
  .videotext-position
    justify-content: center!important
    
