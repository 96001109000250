@import "vars"

.VideoBlockParent
  position: relative

.VideoPreviewText
  position: absolute
  bottom: 0
  color: #fff
  padding: 10px 0px 0px 10px
  margin: 10px 10px 0px 0px
  vertical-align: bottom
  width:  100%
  height:  calc(0% + 70px)
  background: linear-gradient(0deg, rgba(#111,0.5), rgba(#111,0.0))
  border-top-left-radius: 0px
  border-top-right-radius: 0px
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  animation: animationPreviewBg 0.5s
  > h1
    font-size: 1.5rem
    font-style: normal
    animation: animationPreviewText 0.5s
  > span
    font-family: 'Oswald-Light'
    font-style: italic
    animation: animationPreviewText 0.5s
  > img
    position: absolute
    height: 50px
    top: 10px
    right: 10px
    animation: animationPreviewText 1s


@media (max-width: 1254px)
  .VideoPreviewText
    > h1
      font-size: 1.1rem
@media (max-width: 992px)
  .VideoPreviewText
    > h1
      font-size: 1.1rem
@media (max-width: 768px)
  .VideoPreviewText
    > h1
      font-size: 1.5rem


.VideoPreviewPlay
  position: absolute
  top: calc(50% - 25px)
  left: calc(50% - 15px)
  color: rgba(#fff, 1)
  font-size: 2rem

@keyframes animationPreviewBg
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes animationPreviewText
  0%
    opacity: 0
  50%
    opacity: 0
    transform: translateY(10px)
  100%
    opacity: 1
    transform: translateY(0px)
