@import "vars"

.footerVideoProduction
  padding: 0px 0px 30px 5px
  
  background-color: $bgcolor2
  color: $fontcolor2
  line-height: 150%

.footerSocial
  padding: 0px 30px 0px 5px
  background-color: $bgcolor2
  color: $fontcolor2
  line-height: 200%

  

.a
  padding: 10px 10px 10px 0px