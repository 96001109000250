@import "vars"

@font-face
  font-family: 'Hibana'
  src: url('../fonts/Hibana.otf') format('opentype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Oswald'
  src: url('../fonts/Oswald-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Oswald'
  src: url('../fonts/Oswald-Bold.ttf') format('truetype')
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'Oswald'
  src: url('../fonts/Oswald-ExtraLight.ttf') format('truetype')
  font-weight: thin
  font-style: normal

@font-face
  font-family: 'Oswald'
  src: url('../fonts/Oswald-Extra-LightItalic.ttf') format('truetype')
  font-weight: thin
  font-style: italic

hr.divider
    border-top: 3px solid $hrcolor
    opacity: 0.7

.anchor
  display: block
  height: 35px
  visibility: hidden

.intro
  height: 60px

p.color
  color:$color2