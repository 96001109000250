@import "vars"

.header__placeholder
  height: 100px

.navbar
  background: rgba(0, 0, 0, 0.9)!important
  //filter: blur(8px)
  /* #161616!important; */
.navbar-brand
  
  padding-top: 0
.navbar-brand img
  height: 20px
.navbar-nav
  
  padding: 0 0 6px 0
.nav-item
  padding-left: 10px
  a
    font-family: "Oswald"
    color: #fff!important
    font-size: 1.1em
    font-style: normal
    /*letter-spacing: 0.1em;*/
    &:hover
      color: $color6 !important
.active
  a
    color: $color6 !important

.social
  color: $fontcolor2
  text-decoration: none
  font-size: 1.5em
  
.social:hover
  color: $color6
  text-decoration: none
  font-size: 1.5em

.social-black
  color: $fontcolor1
  text-decoration: none
  font-size: 2em

.social-black:hover
  color: $color6
  text-decoration: none
  font-size: 2em
  
.social-block
  text-align: center
  