$color1: #009898
$color2: #03c7c8
$color3: #fff
$color4: #d92cc2
$color5: #ff73ec
$color6: #d81616
$fontcolor1:#111
$fontcolor2:#fff
$hrcolor: #111
$bgcolor1: #fff
$bgcolor2:#000000
$divcolor: #d0d0d0